function MobileChats({ onSelectProfile }) {
    const profiles = [
        { id: 1, name: "Anil Kumar", message: "Wait please, I will let you know" },
        { id: 1, name: "rohit Kumar", message: "Wait please, I will let you know" },
        { id: 1, name: "rohit Kumar", message: "Wait please, I will let you know" },
        { id: 1, name: "rohit Kumar", message: "Wait please, I will let you know" },
        { id: 1, name: "rohit Kumar", message: "Wait please, I will let you know" },
        { id: 1, name: "rohit Kumar", message: "Wait please, I will let you know" },
        { id: 1, name: "rohit Kumar", message: "Wait please, I will let you know" },
        { id: 1, name: "rohit Kumar", message: "Wait please, I will let you know" },
        { id: 1, name: "rohit Kumar", message: "Wait please, I will let you know" },
        { id: 1, name: "rohit Kumar", message: "Wait please, I will let you know" },
        { id: 1, name: "rohit Kumar", message: "Wait please, I will let you know" },
    ];

    return (
        <div className="p-3 space-y-3">
            {profiles.map(profile => (
                <div
                    key={profile.id}
                    onClick={() => onSelectProfile(profile)} // Pass the profile object on click
                    className="flex gap-3 bg-[#EDEDED] py-2 px-2 rounded-xl cursor-pointer hover:shadow-md"
                >
                    <img src="./wtspProfile.png" className="w-12 h-12 rounded-full" alt="Profile" />
                    <div className="mt-1">
                        <h1 className="font-medium">{profile.name}</h1>
                        <p className="font-light text-[14px]">{profile.message}</p>
                    </div>
                    <div className="mt-3 ml-auto flex flex-col items-end">
                        <h1 className="bg-[#43C595] text-[12px] rounded-full text-center w-5 font-bold text-white">1</h1>
                        <p className="text-[#43C595] font-light text-[12px]">10:00 PM</p>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default MobileChats;
