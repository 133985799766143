import { useEffect, useState } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import CalenderBox from "./CalenderBox";
import { url } from "../../urlConfig";
import { ToastContainer, toast } from 'react-toastify';
import Schedule from "../../Pages/Shedules";
function CustomCalender({groupId}) {
  // console.log(groupId)
  const [weekDays, setWeekDays] = useState([
    "monday",
    "tuesday",
    "wednesday",
    "thrusday",
    "friday",
    "saturday",
    "sunday",
  ]);
  const [monthList, setMonthList] = useState([
    "january",
    "febuary",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ]);
  const [month, setMonth] = useState(monthList[new Date().getMonth()]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [dateOfMonth, setDateOfMonth] = useState([]);
  const [selectedShift,setSelectedShift]=useState("")
  const [shift,setShift]=useState([])

  // const [repeatAfter,setRepeatAfter]=useState(10)
  const [schedule,setSchedule]=useState(null)
  const[shiftList,setShiftList]=useState([])
  
const [repeatAfter,setRepeatAfter]=useState(0)

// const [staticShiftListData,setStaticShiftListData]=useState([])
const [counter,setCounter]=useState(0)
const [isScheduled,setIsScheduled]=useState(false)
console.log(groupId)

// useEffect(() => {
// // Directly setting the state
// setStaticShiftListData(shiftListData);  
// }, [shiftListData]); // This will ensure that staticShiftListData gets updated whenever shiftListData changes


  const [shiftListData,setShiftListData]=useState([
    {
      "name":"morning",
      "dates":[
       "2024-11-03","2024-11-04","2024-11-06"
      ]
    },
    {
      "name":"afternoon",
      "dates":[
       "2024-11-10","2024-11-12","2024-11-13"
      ]
    },
    {
      "name":"night",
      "dates":[
       "2024-11-15","2024-11-16","2024-11-17"
      ]
    }
  ])

  const [shiftListData2,setShiftListData2]=useState([
    
  ])

  const [shiftListData3,setShiftListData3]=useState([
    
  ])
  // console.log(new Date().getMonth() + 1);
  let adminId =localStorage.getItem("theAdminId")
 
  useEffect(()=>{
    
    fetch(`${url}/get-all-shifts/${adminId}`,{
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `bearer ${localStorage.getItem('authToken')}`
      },
    }).then((res)=>{return res.json()})
    .then(response=>{
      if(response.status===true){
         setShiftList(response.data)
      }
    })
    fetch(`${url}/schedule/${groupId}`,{
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `bearer ${localStorage.getItem('authToken')}`
      },
    }).then((res)=>{return res.json()})
    .then(response=>{
      if(response.status===true){
        // console.log(response)
        if(response.data!==null){
          console.log(response)
          setShift([...response.data.shift])
          setShiftListData2([...response.data.shift])
          setShiftListData3([...response.data.shift])
          setSchedule(response.data)
          setRepeatAfter(response.data.repeatAfter)
          localStorage.setItem("repeatAfter",response.data.repeatAfter)
          setIsScheduled(true)
        }else{
          setShiftListData2([])
          setShiftListData3([])
          setIsScheduled(false)
          setShift([])
        }
        
        // console.log(response)
      }
    })
  },[groupId])

  


  

 function handleCreateSchedule(){
    if(!groupId){
      toast.error("Please select gropu first")
    }else{
      if(schedule!==null){
        let scheduleId=schedule._id
        let formData={shift,repeatAfter}
        fetch(`${url}/update-schedule/${scheduleId}`,{
          method:"POST",
          headers: {
            'Content-Type': 'application/json',
            "Authorization": `bearer ${localStorage.getItem('authToken')}`
          },
          body:JSON.stringify(formData)
        }).then((res)=>{return res.json()})
        .then((response)=>{
          if(response.status===true){
            toast.success(response.message)
            setIsScheduled(true)
            setMonth(monthList[new Date().getMonth()])
            window.location.reload()
          }else{
            toast.error(response.message)
          }
        })
      }else{
        let scheduleId=""
        let formData={adminId,groupId,shift,repeatAfter}
      fetch(`${url}/create-schedule/${scheduleId}`,{
        method:"POST",
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `bearer ${localStorage.getItem('authToken')}`
        },
        body:JSON.stringify(formData)
      }).then((res)=>{return res.json()})
      .then((response)=>{
        // console.log(response)
        if(response.status===true){
          toast.success(response.message)
          setIsScheduled(true)
          setMonth(monthList[new Date().getMonth()])
          window.location.reload()
        }else{
          toast.error(response.message)
        }
      })
      }
      
    }
  }

  function createDatesOfTheMonth(month, year) {
    let monthNumber = monthList.indexOf(month);

    // Get the first day of the month (October 1, 2024)
    let firstDate = new Date(year, monthNumber, 1);
    let firstDayOfWeek = firstDate.toLocaleString("en-us", { weekday: "long" });

    // Find the total number of days in the month
    let totalDays = new Date(year, monthNumber + 1, 0).getDate(); // Gets the last date of October 2024

    // Create an array to store all the dates of October 2024
    let allDates = [];

    for (let day = 2; day <= totalDays + 1; day++) {
      let date = new Date(year, monthNumber, day);
      allDates.push(date.toISOString().split("T")[0]); // Push date in 'YYYY-MM-DD' format
    }

    // First date details
    let firstDateDetails = {
      date: firstDate.toISOString().split("T")[0], // 'YYYY-MM-DD' format
      day: firstDayOfWeek,
    };
    return { allDates, firstDateDetails };
  }
  useEffect(() => {
    const dates = createDatesOfTheMonth(month, year);
    console.log(dates);
    let dayIndex = weekDays.indexOf(
      dates.firstDateDetails.day.toLocaleLowerCase()
    );
    console.log(dayIndex);
    for (let i = 0; i < dayIndex; i++) {
      dates.allDates.unshift(" "); // or use `dateOfMonth.unshift('');` for empty strings
    }

    setDateOfMonth(dates.allDates);
  }, [month,year,shift,groupId,schedule]);
  return (
    <>
    <ToastContainer/>
      <div className="flex justify-between mt-2 w-[365px]">
        <div className=" capitalize font-bold">
          {month} {year}
        </div>
        <div className="flex gap-2 ">
          <NavigateBeforeIcon
            onClick={() => {
              if (monthList.indexOf(month) === 0) {
                setMonth("december");
                setYear(year - 1);
              } else {
                setMonth(monthList[monthList.indexOf(month) - 1]);
              }
            }}
            sx={{ cursor: "pointer" }}
          />
          <NavigateNextIcon
            sx={{ cursor: "pointer" }}
            onClick={() => {
             
              if (monthList.indexOf(month) === 11) {
                setMonth("january");
                setYear(year + 1);
              } else {
                setMonth(monthList[monthList.indexOf(month) + 1]);
              }
              
            }}
          />
        </div>
      </div>
      <div className="grid w-[365px] grid-cols-7 gap-4 mt-2">
        {weekDays.map((i) => (
          <div className=" capitalize flex w-[55px] h-[55px] justify-center items-center px-0  font-bold ">{i.substring(0, 2)}</div>
        ))}
      </div>
      <div className="grid w-[365px] grid-cols-7 mt-1 flex-wrap" >
        {dateOfMonth.map((i) => (
          <CalenderBox repeatAfter={repeatAfter} setRepeatAfter={setRepeatAfter} shift={shift} setShift={setShift} selectedShift={selectedShift} isScheduled={isScheduled} groupId={groupId} schedule={schedule}  totalMonthDays={dateOfMonth} month={month} setMonth={setMonth}  dates={i} shiftListData={shiftListData} setShiftListData={setShiftListData} staticShiftListData={shiftListData} shiftListData2={shiftListData2} shiftListData3={shiftListData3} setShiftListData2={setShiftListData2}/>
        ))}
      </div>
      <div className="flex mt-5 flex-wrap gap-5 overflow-x-scroll scrollbar-hide">
      {shiftList.map((i) => (
  <div
  className={`justify-center items-center text-center border cursor-pointer px-5 py-1 max-sm:px-10 max-sm:py-0  ${
    selectedShift === i.shiftName
      ? "bg-white text-blue-500 border-blue-500"  // White background, blue text and border when selected
      : i.shiftName === "morning"
      ? "bg-[#009011] text-white border-blue-400"   // Green for morning
      : i.shiftName === "afternoon"
      ? "bg-[#FAC710] text-white border-blue-400"    // Pink for afternoon
      : i.shiftName === "night"
      ? "bg-[#FD474D] text-white border-blue-400"     // Red for night
      : i.shiftName === "open"
      ? "bg-yellow-500 text-white border-blue-400"  // Yellow for open
      : "bg-[#25CCF7] text-white border-blue-400"  // Orange for other shifts
  }`}
  onClick={() => {
    if(!groupId){
      toast.error("Please select a group first");
    } else {
      setSelectedShift(i.shiftName);
      
    }
  }}
  key={i.shiftId} // Always provide a key when mapping over lists
>
  <button className=" text-center capitalize">{i.shiftName}</button>
  <p className="text-[8px] text-center ">{i.start} - {i.end}</p>
</div>

  
))}


      </div>
      <div className="text-[15px] mt-5 flex items-center">
              <input type="checkbox" className="mr-2" />
              Take a break for &nbsp;
              <input
                type='number'
                className="border border-black bg-gray-300 w-8 h-4 py-2 rounded-md text-center mx-2 max-sm:mx-0 sm:mx-0"
                value={repeatAfter}
                onChange={(e)=>{setRepeatAfter(e.target.value)}}
              />&nbsp;
              and repeat this schedule
            </div>
            {isScheduled?   <button className="bg-blue-700 text-white px-5 py-2 rounded-lg lg:w-[50%] max-sm:w-[100%] mt-10 mb-10"
      onClick={()=>{ setIsScheduled(false); toast.success("Start editing dates")}}
      >
                Edit
              </button>
              :
              
      <button className="bg-blue-700 text-white px-5 py-2 rounded-lg lg:w-[50%] max-sm:w-[100%] mt-10 mb-10"
      onClick={handleCreateSchedule}
      >
                Save
              </button>
}
    </>
  );
}

export default CustomCalender;