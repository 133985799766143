import React from "react";
import { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import './App.css';
import Employees from "./Pages/Employees";
import ManageProfile from "./Pages/Profile";
import Chats from "./Pages/Chats";
import AllShift from "./Pages/Shifts";
import Shedule from "./Pages/Shedules";
import SpecialShift from "./Pages/SpecialShift";
import AllRewards from "./Pages/Rewards";
import Leaves from "./Pages/Leave";
import AllReports from "./Pages/Reports";
import Subscription from "./Pages/Subscription";
import PaymentInfo from "./Pages/PaymentPage";
import Login from "./Pages/Login";
import CreateSpecialShift from "./Pages/SpecialShift/createSpecialShift";
import ViewSpecialShift from "./Pages/SpecialShift/viewSpecialShift";
import  { LoginContext }  from './LoginContext'
import Protected from "./Protected";

// import EditSpecialShift from "./Pages/SpecialShift/EditSpecialShift";

function App() {
  const [singleUser,setSingleUser]=useState({})
  return (
    <>
    <LoginContext.Provider value={{singleUser,setSingleUser}}>
    <Router>
      <Routes>
      <Route path='/' element={ <Login/>}></Route>
      <Route path='/chats' element={<Protected Component={Chats}/>}></Route>
      <Route path='/employee' element={<Protected Component={Employees}/>}></Route>
      <Route path='/shifts' element={<Protected Component={AllShift}/>}></Route>
      <Route path='/create-special-shift/:specialId' element={<Protected Component={CreateSpecialShift}/>}></Route>
      <Route path='/special-shift-detail' element={<Protected Component={ViewSpecialShift}/>}></Route>
      <Route path='/schedule' element={<Protected Component={Shedule}/>}></Route>
      <Route path='/specialShift' element={<Protected Component={SpecialShift}/>}></Route>
      {/* <Route path="/edit-special-shift/:shiftId" element={<Protected Component={EditSpecialShift}/>}></Route> */}
      <Route path='/rewards' element={<Protected Component={AllRewards}/>}></Route>
      <Route path='/leave' element={<Protected Component={Leaves}/>}></Route>
      <Route path='/profile' element={<Protected Component={ManageProfile}/>}></Route>
      <Route path='/reports' element={<Protected Component={AllReports}/>}></Route>
      <Route path='/profile/Subscription' element={<Protected Component={Subscription}/>}></Route>
      <Route path='/profile/Subscription/payment' element={<Protected Component={PaymentInfo}/>}></Route>
       
      
      </Routes>
      </Router>
      </LoginContext.Provider>
    </>
  );
}

export default App;
