import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Drawer, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Button } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SchemaOutlinedIcon from '@mui/icons-material/SchemaOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import StarsOutlinedIcon from '@mui/icons-material/StarsOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';

import LogoutIcon from '@mui/icons-material/Logout';
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function SideMenu() {

    const navigate = useNavigate();

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const location = useLocation();

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleLogoutClick = (event) => {
        event.preventDefault(); 
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleConfirmLogout = () => {
       
        localStorage.removeItem('authToken');
        localStorage.removeItem('theAdminId');
        localStorage.removeItem('loginStatus');
    
        
        setModalOpen(false);
    
       
        navigate('/');
    };

    const drawerContent = (
        <ul className="text-[14px] mt-10 lg:ml-4 max-sm:-ml-6 pl-8 font-medium mb-5 mr-10 md:-ml-3">
            <li className="group">
                <NavLink to="/chats" className={({ isActive }) => `block p-2 mt-8 rounded-xl hover:bg-gray-200 pr-4 transition-all duration-300 ${isActive ? "active bg-gray-200" : "group-hover:bg-gray-200"}`}>
                    <ChatBubbleOutlineOutlinedIcon className="mr-3 " />
                    Chats
                </NavLink>
            </li>
            <li className="group ">
                <NavLink to="/employee" className={({ isActive }) => `block p-2 mt-8 rounded-xl hover:bg-gray-200 pr-4 transition-all ${isActive ? "active bg-gray-200" : "group-hover:bg-gray-200"}`}>
                    <PeopleAltOutlinedIcon className="mr-3 mb-1" />
                    Employees
                </NavLink>
            </li>
            <li className="group ">
                <NavLink to="/shifts" className={({ isActive }) => `block p-2 mt-8 rounded-xl hover:bg-gray-200 pr-4 transition-all ${isActive ? "active bg-gray-200" : "group-hover:bg-gray-200"}`}>
                    <PeopleAltOutlinedIcon className="mr-3 mb-1" />
                    Shifts
                </NavLink>
            </li>
            <li className="group ">
                <NavLink to="/schedule" className={({ isActive }) => `block p-2 mt-8 rounded-xl hover:bg-gray-200 pr-4 transition-all ${isActive ? "active bg-gray-200" : "group-hover:bg-gray-200"}`}>
                    <SchemaOutlinedIcon className="mr-3 mb-1" />
                    Schedule
                </NavLink>
            </li>
            <li className="group ">
                <NavLink to="/specialShift" className={({ isActive }) => `block p-2 mt-8 rounded-xl hover:bg-gray-200 pr-4 transition-all ${isActive ? "active bg-gray-200" : "group-hover:bg-gray-200"}`}>
                    <SchemaOutlinedIcon className="mr-3 mb-1" />
                    Special Shift
                </NavLink>
            </li>
           
            <li className="group ">
                <NavLink to="/leave" className={({ isActive }) => `block p-2 mt-8 rounded-xl hover:bg-gray-200 pr-4 transition-all ${isActive ? "active bg-gray-200" : "group-hover:bg-gray-200"}`}>
                    <SchemaOutlinedIcon className="mr-3 mb-1" />
                    Leave
                </NavLink>
            </li>
            <li className="group ">
                <NavLink to="/rewards" className={({ isActive }) => `block p-2 mt-8 rounded-xl hover:bg-gray-200 pr-4 transition-all ${isActive ? "active bg-gray-200" : "group-hover:bg-gray-200"}`}>
                    <StarsOutlinedIcon className="mr-3 mb-1" />
                    Rewards
                </NavLink>
            </li>
          
            <li className="group ">
                <NavLink to="/reports" className={({ isActive }) => `block p-2 mt-8 rounded-xl hover:bg-gray-200 pr-4 transition-all ${isActive ? "active bg-gray-200" : "group-hover:bg-gray-200"}`}>
                    <SummarizeOutlinedIcon className="mr-3 mb-1" />
                    Reports
                </NavLink>
            </li>
            <li className="group ">
                <NavLink to="/profile" className={({ isActive }) => `block p-2 mt-8 rounded-xl hover:bg-gray-200 pr-4 transition-all ${isActive ? "active bg-gray-200" : "group-hover:bg-gray-200"}`}>
                    <SettingsOutlinedIcon className="mr-3 mb-1" />
                    Manage Profile
                </NavLink>
            </li>
            <li className="group ">
                <NavLink to="/" onClick={handleLogoutClick} className={({ isActive }) => `block p-2 mt-8 rounded-xl hover:bg-gray-200 pr-4 transition-all ${isActive ? "active bg-gray-200" : "group-hover:bg-gray-200"}`}>
                    <LogoutIcon className="mr-3 mb-1" />
                    Logout
                </NavLink>
            </li>
        </ul>
    );

    return (
        <>
            <div className="w-[20%] bg-gray-50 border-r-2 overflow-y-auto hidden lg:block">
                <div className="flex justify-center pt-6 sticky top-2 bg-gray-50 pb-14">
                    <img src="/4Shifter.png" className="lg:w-[200px] mt-3" />
                </div>
                {drawerContent}
            </div>
            <div className="lg:hidden absolute w-[95vw] bg-white max-sm:ml-3 sm:ml-2">
                <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
                    <MenuIcon />
                </IconButton>
                <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
                    <div className="w-[230px] max-sm:w-[210px] bg-gray-50" role="presentation" onClick={handleDrawerToggle} onKeyDown={handleDrawerToggle}>
                        <div className="flex justify-center pt-6 bg-gray-50 lg:pb-14">
                            <img src="/4Shifter.png" className="w-[200px] max-sm:w-[150px] md:w-[170px] md:-ml-2 max-sm:-ml-4 sm:w-[150px] mt-3" />
                        </div>
                        {drawerContent}
                    </div>
                </Drawer>
            </div>

            {/* Logout Confirmation Modal */}
            <Dialog open={modalOpen} onClose={handleCloseModal}>
                <DialogTitle sx={{fontSize:"24px"}} className=" text-center font-bold text-[#FD474D]"> Logout</DialogTitle>
                <DialogContent sx={{fontSize:"22px"}}>
                    Are you sure you want to logout?
                </DialogContent>
                <DialogActions className="lg:mr-5 mb-5">
                <button onClick={handleConfirmLogout} className="border border-green-500 text-[18px] font-semibold bg-[#FD474D] text-white px-16 py-2 rounded-2xl hover:bg-white hover:text-black ">
                        Yes
                    </button>
                    <button onClick={handleCloseModal}className="border border-green-500 bg-[#FD474D] text-[18px] font-semibold text-white px-16 py-2 rounded-2xl hover:bg-white hover:text-black">
                        No
                    </button>
                    
                </DialogActions>
            </Dialog>
        </>
    );
}

export default SideMenu;
