import React, { useEffect, useState } from "react";
import SideMenu from "../../Components/Sidebar/sidebar";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomDatePicker from "../../Components/TimePicker/datePicker";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { url } from "../../urlConfig";

function AllReports() {
    const [employee, setEmployee] = useState('');
    const [employees, setEmployees] = useState([]);
    const [isLoading, setLoad] = useState(true);
    const adminId = localStorage.getItem('theAdminId');
    const profileImage = localStorage.getItem("profile");
    const contactPerson = localStorage.getItem("contactPerson") || "Admin";
    const email = localStorage.getItem("email") || "default@example.com";
    const parsedProfileImage = profileImage ? JSON.parse(profileImage) : null;

    const handleChange = (event) => {
        setEmployee(event.target.value);
    };

    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const response = await fetch(`${url}/all-employees/${adminId}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                        "Content-Type": "application/json",
                    },
                });

                const data = await response.json();
                console.log("Full API Response:", data);

                if (data.status === true) {
                    setEmployees(data.data || []);
                    console.log("Employee Data:", data.data);
                } else {
                    console.error("Failed to fetch employees:", data.message);
                }
            } catch (error) {
                console.error("Error fetching employees:", error);
            } finally {
                setLoad(false);
            }
        };

        fetchEmployees();
    }, [adminId]);

    const exportAsCSV = () => {
        const allShiftsData = [
            ["Date", "Shift Type", "Shift Start & End", "Break Hours", "Work Hours", "Coin Reward"],
            ["03-05-2024", "Morning", "08:00 - 16:00", "0.50", "7.50", "0 Coins"],
            ["30-05-2024", "Special Shift", "08:00 - 16:00", "0.50", "7.50", "10 Coins"],
            ["30-05-2024", "Special Shift", "08:00 - 17:00", "0.50", "8.50", "5 Coins"]
        ];

        let csvContent = "All Shifts\n";
        allShiftsData.forEach(row => {
            csvContent += row.join(",") + "\n";
        });

        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "all_shifts_data.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="flex w-full h-screen">
            <SideMenu />
            <div className="lg:w-[81%] lg:p-5 md:p-5 max-sm:p-4 overflow-scroll scrollbar-hide px-2">
                {/* Navbar */}
                <div className="flex justify-between z-50 sticky -top-5 pb-5 max-sm:top-7 bg-white max-sm:mt-10 sm:mt-10 md:mt-5 ">
                    <div className="mt-3">
                        <h1 className="text-[22px] font-semibold">Reporting</h1>
                        <p className="capitalize">Here you can extract Employees data and KPIs</p>
                    </div>
                    <div className="flex space-x-2 mt-3 mr-3 max-sm:hidden cursor-pointer">
                        <img
                            src={parsedProfileImage || "https://cdn-icons-png.flaticon.com/512/8847/8847419.png"}
                            className="h-[40px] w-[40px] rounded-full"
                            alt={"admin profile"}
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "https://cdn-icons-png.flaticon.com/512/8847/8847419.png";
                            }}
                        />
                        <div className="text-[14px]">
                            <h1 className="text-blue-700 font-semibold">{contactPerson}</h1>
                            <p>{email || "not found"}<ExpandMoreIcon /></p>
                        </div>
                    </div>
                </div>

                {/* Select employee details */}
                <div className="mt-10 lg:flex justify-between">
                    <div>
                        <h1 className="font-semibold text-[22px]">Set Time Filter</h1>
                        <div className="lg:flex md:flex max-sm:flex-none max-sm:space-y-5 items-center lg:gap-4 mb-2">
                            <CustomDatePicker label="Start Date" /> - <CustomDatePicker label="End Date" />
                        </div>
                    </div>
                    <div>
                        <h1 className="font-semibold text-[22px] lg:mr-40 md:w-full max-sm:mt-5">Select Employee Here</h1>
                        <Box sx={{ minWidth: 120, marginTop: "8px" }}>
                            <FormControl className="md:w-[65%] lg:w-full max-sm:w-full">
                                <InputLabel id="demo-simple-select-label">All Employees</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={employee}
                                    label="All Employee"
                                    onChange={handleChange}
                                    sx={{
                                        borderRadius: '12px',
                                    }}
                                >
                                    {employees.map((emp) => (
                                        <MenuItem key={emp.id} value={emp.id}>{emp.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                </div>

                {/* Profile area */}
                <div className="mt-5">
                    <h1 className="font-semibold text-[22px] lg:mr-40">Selected Employee: _______</h1>
                    <div className="mt-5 flex gap-8">
                        <img
                            src={"https://cdn-icons-png.flaticon.com/512/8847/8847419.png"}
                            className="h-[80px] w-[80px] rounded-full"
                            alt={"admin profile"}
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "https://cdn-icons-png.flaticon.com/512/8847/8847419.png";
                            }}
                        />
                        <div>
                            <div className="leading-5 mb-2">
                                <p className="opacity-50">APPROVED / ALLOCATED LEAVES THIS YEAR</p>
                                <p>0/20</p>
                            </div>
                            <div className="leading-5">
                                <p className="opacity-50">GROUP</p>
                                <p>Construction Group</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Total Summary Table */}
                <div className="mt-20">
                    <div className="flex justify-between">
                        <h1 className="font-medium mb-2">Total Summary</h1>
                        <button className="bg-blue-700 text-white px-3 py-2 mb-5 hover:bg-blue-800" onClick={exportAsCSV}>
                            Export As CSV
                        </button>
                    </div>
                    <div className="overflow-x-auto scrollbar-hide">
                        <table className="table-auto w-full text-center text-sm">
                            <thead>
                                <tr className="bg-gray-100">
                                    <th className="py-3 px-6">Days</th>
                                    <th className="py-3 px-6">Sum Of Shifts</th>
                                    <th className="py-3 px-6">Sum Of Leave Request</th>
                                    <th className="py-3 px-6">Break Hours</th>
                                    <th className="py-3 px-6">Work Hours</th>
                                    <th className="py-3 px-6">Sum Of Coin Rewards</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="py-3 px-6">3</td>
                                    <td className="py-3 px-6">3</td>
                                    <td className="py-3 px-6">2</td>
                                    <td className="py-3 px-6">1.50</td>
                                    <td className="py-3 px-6">23.50</td>
                                    <td className="py-3 px-6">15 Coins</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* All Shifts Table */}
                <div className="mt-10">
                    <h1 className="font-medium mb-2">All Shifts</h1>
                    <div className="overflow-x-auto scrollbar-hide">
                        <table className="table-auto w-full text-center text-sm">
                            <thead>
                                <tr className="bg-gray-100">
                                    <th className="py-3 px-6">Date</th>
                                    <th className="py-3 px-6">Shift Type</th>
                                    <th className="py-3 px-6">Shift Start & End</th>
                                    <th className="py-3 px-6">Break Hours</th>
                                    <th className="py-3 px-6">Work Hours</th>
                                    <th className="py-3 px-6">Coin Reward</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="py-3 px-6">03-05-2024</td>
                                    <td className="py-3 px-6">Morning</td>
                                    <td className="py-3 px-6">08:00 - 16:00</td>
                                    <td className="py-3 px-6">0.50</td>
                                    <td className="py-3 px-6">7.50</td>
                                    <td className="py-3 px-6">0 Coins</td>
                                </tr>
                                <tr>
                                    <td className="py-3 px-6">30-05-2024</td>
                                    <td className="py-3 px-6">Special Shift</td>
                                    <td className="py-3 px-6">08:00 - 16:00</td>
                                    <td className="py-3 px-6">0.50</td>
                                    <td className="py-3 px-6">7.50</td>
                                    <td className="py-3 px-6">10 Coins</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AllReports;
