import React, { useState, useEffect } from 'react';
import Navbar from "../../Components/Navbar/navbar";
import SideMenu from "../../Components/Sidebar/sidebar";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import SheduleCalender from '../../Components/Calender';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { url } from '../../urlConfig';
import { Link, Navigate } from 'react-router-dom';
import CustomCalender from '../../Components/Calender/CustomCalender';

const GroupModal = ({ open, onClose, onSubmit, groupName, setGroupName }) => {
  if (!open) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-5 rounded-lg w-[400px]">
        <h2 className="text-xl font-bold mb-4">{groupName ? 'Edit Group' : 'Create Group'}</h2>
        <input
          type="text"
          placeholder="Enter group name"
          className="border border-gray-400 p-2 w-full mb-4"
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
        />
        <div className="flex justify-end space-x-3">
          <button onClick={onClose} className="px-4 py-2 bg-gray-500 text-white rounded-lg">
            Cancel
          </button>
          <button onClick={onSubmit} className="px-4 py-2 bg-blue-700 text-white rounded-lg">
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

function Schedule() {
  const [groups, setGroups] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentGroupId, setCurrentGroupId] = useState(null);
  const [groupName, setGroupName] = useState('');
  const [load, setLoad] = useState(true);
  const [groupId,setGroupId]=useState("")
  const adminId = localStorage.getItem('theAdminId');
  const [schedule,setSchedule]=useState(null)
  useEffect(() => {
    const fetchGroups = async () => {
      try {
        if (!adminId) {
          toast.error("Admin ID not found in local storage");
          return;
        }

        const response = await fetch(`${url}/get-all-groups/${adminId}`, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `bearer ${localStorage.getItem('authToken')}`
          }
        });

        const data = await response.json();
        console.log("Fetched groups data:", data);
        if (data.status) {
          setGroups(data.data);
        
          console.log(data)
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        toast.error("Error fetching groups: " + error.message);
      } finally {
        setLoad(false)
      }
    };

    fetchGroups();
  }, [adminId]);

  

  const handleCreateGroup = () => {
    setGroupName('');
    setIsEditMode(false);
    setIsModalOpen(true);
  };

  const handleEditGroup = (group) => {
    console.log("Editing group:", group);
    setGroupName(group.groupName);
    setCurrentGroupId(group);
    setIsEditMode(true);
    setIsModalOpen(true);
  };

  const handleSaveGroup = async () => {
    if (!groupName.trim()) {
      toast.error("Group name cannot be empty");
      return;
    }

    try {
      let response;
      const adminId = localStorage.getItem('theAdminId');
      if (!adminId) {
        toast.error("Admin ID not found in local storage");
        return;
      }

      if (isEditMode) {
        response = await fetch(`${url}/edit-group`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            "Authorization": `bearer ${localStorage.getItem('authToken')}`
          },
          body: JSON.stringify({ groupId: currentGroupId, groupName }),
        });

        const data = await response.json();
        if (data.status) {
          console.log("Response from edit group:", data);
          setGroups(groups.map((group) =>
            group.id === currentGroupId ? { ...group, groupName } : group
          ));
          toast.success(data.message);
          window.location.reload();
        } else {
          toast.error(data.message);
        }
      } else {
        response = await fetch(`${url}/create-group`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            "Authorization": `bearer ${localStorage.getItem('authToken')}`
          },
          body: JSON.stringify({ adminId, groupName }),
        });

        const data = await response.json();

        if (data.status) {
          setGroups([...groups, { id: data.data.id, groupName: data.data.groupName, members: data.data.members }]);
          toast.success(data.message);
          window.location.reload();
        } else {
          toast.error(data.message);
        }
      }
    } catch (error) {
      toast.error("Error saving group: " + error.message);
    }

    setIsModalOpen(false);
  };

  const handleDeleteGroup = async (groupId) => {
    if (window.confirm("Are you sure you want to delete this group?")) {
      try {
        const response = await fetch(`${url}/delete-group/${adminId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            "Authorization": `bearer ${localStorage.getItem('authToken')}`
          },
          body: JSON.stringify({ adminId, groupId }),
        });

        const data = await response.json();
        if (data.status) {
          setGroups(groups.filter((group) => group.id !== groupId));
          toast.success(data.message);
          window.location.reload();
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        toast.error("Error deleting group: " + error.message);
      }
    }
  };

  return (
    <div className="flex w-full h-screen">
      <SideMenu />
      <div className="lg:w-[81%] md:w-full sm:w-full max-sm:w-[100%] px-3 overflow-scroll scrollbar-hide">
        <Navbar />
        <div className="lg:-ml-0  mt-5 sticky top-28 bg-white max-sm:mt-5 space-x-8 sm:ml-5">
                    <input
                        style={{ fontFamily: 'Montserrat' }}
                        className='w-[400px] h-12 rounded-xl bg-[#f5f3f3] outline-none pl-4 mb-5'
                        placeholder='Search here ....'
                    />
                </div>
        <div className="lg:flex lg:-ml-9 ">
          {/* Groups Section */}
          <div className="lg:w-[40%]  p-3  max-sm:mt-12 ml-8 max-sm:ml-0 ">
            <div className="flex justify-between mb-4">
              <h1 className="text-[22px] font-semibold">Groups</h1>
              <button
               style={{ fontFamily: 'Inder' }}
                onClick={handleCreateGroup}
                className="bg-blue-700 rounded-lg px-3 text-white py-2"
              >
                + Create Group
              </button>
            </div>
            <div className=' capitalize'>Manage all your employee groups and schedules effortlessly here </div>
            <div className="-ml-5 mt-5 overflow-scroll  h-[365px]">
              {load ? (
                  <div className="flex justify-center text-center mt-10 font-bold text-lg">
                    <HourglassEmptyIcon className="animate-spin" /> &nbsp; Loading...
                  </div>
                ) : groups.length === 0 ? (
                  <p className="text-center mt-10 font-semibold italic">No groups available</p>
                ) : (
                    groups.map((group) => (
                      <div key={group.id}>
                        {group._id===groupId?
                         <div className="flex justify-between font-medium mt-2 w-[90%] max-sm:w-full cursor-pointer bg-slate-100 rounded-lg px-5 py-2"
                         onClick={()=>{setGroupId(group._id);console.log(group);  setSchedule(group?.schedule)}}
                         >
                           <p>{group?.groupName} ({group?.members.length} members)</p>
                           <div className="flex space-x-2">
                             <button onClick={() => handleEditGroup(group)}>
                               <BorderColorOutlinedIcon />
                             </button>
                             <button onClick={() => handleDeleteGroup(group._id)}>
                               <DeleteOutlinedIcon />
                             </button>
                           </div>
                         </div>
                        :
                        <div className="flex justify-between font-medium mt-2 w-[90%] max-sm:w-full cursor-pointer hover:bg-slate-100 rounded-lg px-5 py-2"
                        onClick={()=>{setGroupId(group._id)}}
                        >
                          <p>{group?.groupName} ({group?.members.length} members)</p>
                          <div className="flex space-x-2">
                            <button onClick={() => handleEditGroup(group)}>
                              <BorderColorOutlinedIcon />
                            </button>
                            <button onClick={() => handleDeleteGroup(group._id)}>
                              <DeleteOutlinedIcon />
                            </button>
                          </div>
                        </div>
}
                        <div className="border-t border-dashed border-gray-300 mt-5 w-[90%]"></div>
                      </div>
                    ))
                  )}
            </div>
          </div>

          <div className="border border-gray-300 h-auto mt-10"></div>

          {/* Shifts Calendar */}
          <div className="px-10 max-sm:px-0  mt-2 w-[60%] max-sm:w-[100%]">
            <div className="flex justify-between mb-4">
              <h1 className="text-[22px] font-semibold">Assembling</h1>
              <Link to={"/shifts"}>
              <button  style={{ fontFamily: 'Inder' }} className="bg-blue-700 rounded-lg px-4 text-white py-2">+ Create Shift</button>
              </Link>
            </div>
            <p className=' capitalize'>Manage your group schedule calendar from here easily</p>
            <div className='z-0 overflow-scroll scrollbar-hide h-[410px]'>
              <CustomCalender groupId={groupId} schedule={schedule}/>
              
            {/* Group Members Section  */}
            <div>
              <h1 className="text-[22px]  font-bold">Group Members (3)</h1>
              <div className="lg:flex md:flex gap-5 max-sm:space-y-5 mt-5">
                <div className="flex space-x-2 mr-3">
                  <img src="https://png.pngtree.com/png-vector/20231019/ourmid/pngtree-user-profile-avatar-png-image_10211471.png" className="h-[40px] w-[40px] rounded-full" />
                  <div className="text-[14px]">
                    <h1 className="text-blue-700 font-semibold">brainbox user </h1>
                    <p>testuser@gmail.com</p>
                  </div>
                </div>
                <div className="flex space-x-2 mr-3">
                  <img src="https://png.pngtree.com/png-vector/20231019/ourmid/pngtree-user-profile-avatar-png-image_10211471.png" className="h-[40px] w-[40px] rounded-full" />
                  <div className="text-[14px]">
                    <h1 className="text-blue-700 font-semibold">brainbox user </h1>
                    <p>testuser@gmail.com</p>
                  </div>
                </div>
                <div className="flex space-x-2 mr-3">
                  <img src="https://png.pngtree.com/png-vector/20231019/ourmid/pngtree-user-profile-avatar-png-image_10211471.png" className="h-[40px] w-[40px] rounded-full" />
                  <div className="text-[14px]">
                    <h1 className="text-blue-700 font-semibold">brainbox user </h1>
                    <p>testuser@gmail.com</p>
                  </div>
                </div>
              </div>
              {/* <div className='italic text-center font-semibold mt-3'>No Members Available In This Group</div> */}
             
            </div>
            </div>
           
           

          </div>
        </div>
      </div>

      {/* Group Modal */}
      <GroupModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleSaveGroup}
        groupName={groupName}
        setGroupName={setGroupName}
      />

      {/* Toast Container */}
      <ToastContainer />
    </div>
  );
}

export default Schedule;
