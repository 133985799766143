import DoneIcon from '@mui/icons-material/Done';
import { Button } from '@mui/material';
import { NavLink } from "react-router-dom";

function SubscriptionCard() {
    return (
        <>
            <div className=" border-2 border-green-200 rounded-lg lg:w-[22%] w-full ">
                <div className="bg-blue-700 text-[30px] p-3 text-start max-sm:leading-8  text-white rounded-t-lg">
                    <h1 className="font-bold ">Standard</h1>
                    <p className="font-semibold">3,50 €  <span className="line-through ml-14">5,50 €</span></p>
                    <h2 className="text-[25px] font-medium">per user/month</h2>
                </div>
                <div>
                    <h2 className="text-[18px] font-medium p-3 text-start">Best for small teams</h2>
                    <div className="text-[20px] font-medium  p-3 text-start space-y-2 max-sm:space-y-0">
                        <div><DoneIcon />&nbsp; Employee groups</div>
                        <div><DoneIcon />&nbsp; Manage shifts & schedule</div>
                        <div><DoneIcon />&nbsp; Leave request</div>
                        <div><DoneIcon />&nbsp; Special shifts</div>
                    </div>
                </div>
                <div className='p-2 mt-5 mb-5'>
                <NavLink to="/profile/Subscription/payment" className="bg-blue-700 hover:bg-blue-800 text-white text-[16px] font-bold  px-28 py-3  rounded-lg ">
                    Select
                </NavLink>
                </div>
                
            </div>
        </>

    );
}

export default SubscriptionCard;