import React, { useState } from "react";
import Navbar from "../../Components/Navbar/navbar";
import SideMenu from "../../Components/Sidebar/sidebar";
import SendIcon from '@mui/icons-material/Send';
import MobileChats from "../../Components/AllChats/mobileViewChat";

function Chats() {
  const [selectedChat, setSelectedChat] = useState(null);
  const [messageInput, setMessageInput] = useState("");
  





  // Mock data for messages with incoming and outgoing flags
  const [messages, setMessages] = useState([
    { text: "hyyy, how are you>>>", time: "2:30 am", type: "incoming" },
    { text: "i am ok now", time: "2:30 am", type: "outgoing" },
    { text: "nice", time: "2:30 am", type: "incoming" },
    { text: "what about u??", time: "2:30 am", type: "outgoing" },
  ]);

  const openChat = (profile) => {
    setSelectedChat(profile);
  };

  const closeChat = () => {
    setSelectedChat(null);
  };

  const handleSendMessage = () => {
    if (messageInput.trim() !== "") {
      const newMessage = {
        text: messageInput,
        time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        type: "outgoing",
      };
      setMessages([...messages, newMessage]);
      setMessageInput(""); // Clear input
    }
  };

  return (
    <div className="flex w-full h-screen">
      <SideMenu />
      <div className="lg:w-[81%] max-sm:w-[100%] overflow-scroll scrollbar-hide">
     
     


        {!selectedChat && (
          <>
            <div className="mt-10 ml-3 sticky top-0 max-sm:top-10 lg:hidden "><Navbar /></div>
            <div className="flex mt-15 sticky top-28 max-sm:top-24 bg-white max-sm:mt-5 space-x-8 sm:ml-5 max-sm:ml-2 lg:hidden">
              <input
                style={{ fontFamily: 'Montserrat' }}
                className="w-[400px] h-12 rounded-xl bg-[#f5f3f3] outline-none pl-4 mb-5"
                placeholder="Search here ...."
              />
            </div>
               
      <div className=" max-sm:hidden sm:hidden  flex justify-center text-[100px] rotate-45 mt-44">
          <div>In Progress</div>
</div>
          </>
        )}

        {selectedChat ? (
          <div className="p-2 bg-white flex flex-col h-[100vh] mt-10">
            <div className="flex items-center justify-between bg-[#EDEDED] p-2 rounded-xl sticky top-0 z-10">
              <div className="flex items-center gap-3">
                <img src="./wtspProfile.png" className="w-12 h-12 rounded-full" alt="Profile" />
                <h1 className="font-medium">{selectedChat.name}</h1>
              </div>
              <button onClick={closeChat} className="text-[#43C595] hover:bg-[#8dc4b0] hover:text-white px-4 py-1 rounded-xl">Back</button>
            </div>

            {/* Chat messages (scrollable) */}
            <div className="flex-grow overflow-y-auto scrollbar-hide p-3 space-y-2 mb-20">
  {messages.map((message, index) => (
    <div
      key={index}
      className={`flex flex-col w-[70%]  ${
        message.type === "incoming"
          ? "self-start bg-[#B8CEF3] rounded-tr-xl rounded-b-xl"
          : "self-end bg-[#D5F5E7] rounded-tl-xl rounded-b-xl ml-auto" 
      } p-3`}
    >
      <p className="text-sm ">{message.text}</p>
      <span className="text-xs text-gray-500 mt-1 text-right">{message.time}</span>
    </div>
  ))}
</div>

            {/* Message input (fixed at the bottom) */}
            <div className="flex items-center p-3 bg-[#f5f3f3] rounded-xl fixed bottom-0 w-[95%]">
              <input
                value={messageInput}
                onChange={(e) => setMessageInput(e.target.value)}
                className="flex-grow outline-none bg-transparent p-2"
                placeholder="Type a message..."
              />
              <SendIcon className="cursor-pointer text-[#43C595]" onClick={handleSendMessage} />
            </div>
          </div>
        ) : (
          <div className="lg:hidden sm:visible">
            <MobileChats onSelectProfile={openChat} />
          </div>  
        )}
      </div>
    </div>
  );
}

export default Chats;
