import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CustomCalender from '../Calender/CustomCalender';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { url } from '../../urlConfig';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import NativeSelect from '@mui/material/NativeSelect';
function EmployeeEditModal({ open, handleClose, employee, employees, setEmployees, groupId2 }) {
    const [profileModalOpen, setProfileModalOpen] = useState(false);
    const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
    const [groupId, setGroupId] = useState(groupId2);
    const [groups, setGroups] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        profileImage: "",
        group: '',
        notes:"",
        leaves: '',
    });

    useEffect(() => {
        setGroupId(groupId2)
        if (employee) {
            setFormData({
                name: employee.name || '',
                email: employee.email || '',
                phone: employee.phone || '',
                profileImage: employee.profileImage || "NoN",
                group: employee.group || 'NoN',
                notes:employee.notes || "NoN",
                leaves: employee.leaves || 'NoN',
            });
        }
    }, [employee]);



    useEffect(() => {
        const fetchGroups = async () => {


            try {
                const response = await fetch(`${url}/get-all-groups/${adminId}`, {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `bearer ${localStorage.getItem('authToken')}`
                    }
                });

                console.log("API Response status:", response.status);
                const data = await response.json();
                console.log("API Response data:", data);

                if (data.status) {
                    setGroups(data.data);
                    console.log("Groups fetched:", data.data);
                } else {
                    toast.error(data.message);
                }
            } catch (error) {
                console.error('Error fetching groups:', error);
                toast.error('Failed to fetch groups.');
            }
        };

        fetchGroups();
    }, [groupId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handelNotesChange =(e) =>{
        const{notes, value} = e.target;
        setFormData((prev) =>({...prev , [notes]:value}));
    }

    const handleProfileOpen = () => {
        handleClose();
        setProfileModalOpen(true);
    };

    const handleProfileClose = () => setProfileModalOpen(false);

    const handleScheduleOpen = () => {
        handleClose();
        setScheduleModalOpen(true);
    };

    const handleScheduleClose = () => setScheduleModalOpen(false);

    const adminId = localStorage.getItem('theAdminId');

    const handleSave = async () => {
        try {
            const response = await fetch(`${url}/edit-employee/${adminId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    employeeId: employee._id,
                    adminId: adminId,
                    name: formData.name,
                    email: formData.email,
                    phone: formData.phone,
                    profileImage: formData.profileImage,
                    groupId: groupId,
                }),
            });

            const data = await response.json();

            if (data.status == true) {
                toast.success(data.message);
                const filteredData = employees.filter(i => {
                    return i._id !== data?.data._id
                })
                const filteredData2 = employees.find(i => {
                    return i._id == data?.data._id
                })
                const index = employees.indexOf(filteredData2)
                console.log(index)
                await filteredData.splice(index, 0, data?.data)

                setEmployees([...filteredData])
                handleProfileClose();
                window.location.reload();

            } else {
                toast.error(data.message);
            }
        } catch (error) {
            console.error("Error saving employee data:", error);
            toast.error("An error occurred while saving employee data.");
        }
    }

    return (
        <>
            {/* Main Modal */}
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box className="p-4 text-[14px] font-medium text-center bg-white rounded-lg shadow-lg space-y-3" style={{ width: '300px', margin: '250px auto' }}>
                    <button className='hover:bg-gray-200 rounded-xl px-5 py-1' onClick={handleScheduleOpen}>Employee Group Schedule</button><br />
                    <button className='hover:bg-gray-200 rounded-xl px-5 py-1' onClick={handleProfileOpen}>Manage Profile</button><br />
                    <button className='hover:bg-gray-200 rounded-xl px-5 py-1'>Leave Requests</button>
                </Box>
            </Modal>

            {/* Detailed Profile Modal */}
            <Modal
                open={profileModalOpen}
                onClose={handleProfileClose}
            >
                <Box className="bg-white rounded-3xl shadow-lg max-w-lg max-sm:w-[95%]  mx-auto mt-5 p-6  pt-2">
                    <h2 className="text-center text-[22px] font-semibold mb-6">Manage Employee Profile</h2>
                    <div className="flex flex-col ">
                        <img
                            src={formData.profileImage && formData.profileImage !== 'NON' ? formData.profileImage : "https://cdn-icons-png.flaticon.com/512/8847/8847419.png"}
                            alt="Profile"
                            className="h-[70px] w-[70px] ml-7 rounded-full mb-4"
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "https://cdn-icons-png.flaticon.com/512/8847/8847419.png";
                            }}
                        />

                        <div className="w-full ml-10">
                            <label className='text-[16px]  text-gray-500'>NAME</label><br />
                            <input
                                type='text'
                                name='name'
                                value={formData.name}
                                onChange={handleInputChange}
                                className='text-[18px] border-none  outline-none'
                            />
                            <div className="border-t border-dashed border-gray-300 pt-4 max-sm:pt-6 w-[70%]  "></div>

                            <label className='text-[16px]  text-gray-500'>EMAIL</label><br />
                            <input
                                type='text'
                                name='email'
                                value={formData.email}
                                onChange={handleInputChange}
                                className='text-[18px] w-[70%]  border-none outline-none'
                            />
                            <div className="border-t border-dashed border-gray-300 pt-4  max-sm:pt-6 w-[70%]  "></div>

                            <label className='text-[16px] text-gray-500'>PHONE NUMBER</label><br />
                            <input
                                type='text'
                                name='phone'
                                value={formData.phone}
                                onChange={handleInputChange}
                                className='text-[18px] border-none outline-none'
                            />
                            <div className="border-t border-dashed border-gray-300 pt-4  max-sm:pt-6 w-[70%]  "></div>

                            {/* <label className='text-[16px]  text-gray-500'>ORGANISATION</label><br />
                            <input
                                type='text'
                                name='organisation'
                                value={formData.organizationName}
                                readOnly
                                // onChange={handleInputChange}
                                className='text-[18px]  border-none outline-none'
                            />
                            <div className="border-t border-dashed border-gray-300 pt-4 w-[70%]  "></div> */}

                            <Box>
                                <FormControl className='w-[70%] '>
                                    <label className='text-[16px] text-gray-500 -mb-3'>GROUP</label>
                                    <NativeSelect
                                        value={groupId}
                                        onChange={(e) => {
                                            console.log("Selected Group ID:", e.target.value);
                                            setGroupId(e.target.value);
                                        }}
                                        inputProps={{
                                            name: 'group',
                                            id: 'uncontrolled-native',
                                        }}
                                        sx={{ height: "20px", fontSize: 18 }}
                                        className="border-none "
                                        disableUnderline
                                    >
                                        {/* <option></option> */}
                                        {groups.map((group) => (
                                            <option key={group._id} value={group._id}  >
                                                {group.groupName}
                                            </option>
                                        ))}
                                    </NativeSelect>
                                </FormControl>
                            </Box>




                            {/* <FormControl className="mb-4 w-[50%] ">
                                <label className='text-[16px]  text-gray-500'>GROUP</label>
                                <Select
                                    sx={{ height: "40px" }}
                                    className='border-none'
                                    value={groupId}
                                    onChange={(e) => {
                                        console.log("Selected Group ID:", e.target.value);
                                        setGroupId(e.target.value);
                                    }}
                                    label="Send request to Group :"
                                >
                                    {groups.map((group) => (
                                        <MenuItem key={group._id} value={group._id}>
                                            {group.groupName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl> */}
                            <div className="border-t border-dashed border-gray-300 pt-4  max-sm:pt-6 w-[70%]  "></div>

                            <label className='text-[16px]  text-gray-500'>NOTES</label><br />
                            <input
                                type='text'
                                name='notes'
                                value={formData.notes}
                                readOnly
                                onChange={handelNotesChange}
                                className='text-[18px] border-none  outline-none'
                            />

                            <div className="border-t border-dashed border-gray-300 pt-4  max-sm:pt-6 w-[70%]  "></div>

                            <label className='text-[16px]  text-gray-500'>APPROVED LEAVES / ALLOCATED LEAVES</label><br />
                            <input
                                type='text'
                                name='leaves'
                                value={formData.leaves}
                                readOnly
                                className='text-[18px]  border-none outline-none'
                            />
                        </div>
                    </div>
                    <button
                        onClick={handleSave}
                        className="mt-10 px-32 lg:ml-20  max-sm:ml-10 py-3 bg-blue-600 text-white rounded-lg font-semibold ">
                        Save
                    </button>
                </Box>
            </Modal>

            {/* Employee Schedule Modal */}
            <Modal
                open={scheduleModalOpen}
                onClose={handleScheduleClose}
            >
                <Box className="bg-white rounded-lg shadow-lg max-w-lg lg:h-[95%] mx-auto max-sm:w-[95%] max-sm:mt-10 mt-2  pt-2">
                    <h2 className="text-center text-[22px] font-semibold mb-6"> Employee Schedule</h2>
                    <p className="text-[16px] font-regular text-center mb-6">
                        Manage Your Group Schedule from Schedule Section Easily
                    </p>
                    <div className="calendar-container text-center p-5  ml-10 max-sm:ml-0 -mt-5">
                        <CustomCalender groupId={employee?.groupId} schedule={employee?.schedule} />
                    </div>
                </Box>
            </Modal>

            {/* Toast Container */}
            <ToastContainer />
        </>
    );
}

export default EmployeeEditModal;
