import { useEffect, useState } from "react";
import EmployeesCard from "../../Components/EmployeesCard";
import Navbar from "../../Components/Navbar/navbar";
import SideMenu from "../../Components/Sidebar/sidebar";
import { url } from "../../urlConfig";
import EmployeeEditModal from "../../Components/EmployeeEditModal";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import Modal from "@mui/material/Modal";
import { Button, TextField, MenuItem } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

function Employees() {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [load, setLoad] = useState(true);
  const [showAddModal, setShowAddModal] = useState(false);
  const [phone, setPhone] = useState("");
  const [groups, setGroups] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [newEmployee, setNewEmployee] = useState({
    name: "",
    email: "",

    profileImage: "null",
    notes: "",
    groupId: "",
  });

  const adminId = localStorage.getItem('theAdminId');

  // Fetch Employees
  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await fetch(`${url}/all-employees/${adminId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();
        console.log("Full API Response:", data);

        if (data.status === true) {
          setEmployees(data.data || []);
          console.log("Employee Data:", data.data);
        } else {
          console.error("Failed to fetch employees:", data.message);
        }
      } catch (error) {
        console.error("Error fetching employees:", error);
      } finally {
        setLoad(false);
      }
    };

    fetchEmployees();
  }, [adminId]);

  const filteredEmployees = employees.filter(
    (employee) =>
      employee.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      employee.phone?.includes(searchQuery)
  );

  // Fetch Groups
  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await fetch(`${url}/get-all-groups/${adminId}`, {
          method: 'GET',
          headers: {

            "Authorization": `bearer ${localStorage.getItem('authToken')}`,
            "Content-Type": "application/json",
          }
        });

        console.log("API Response status:", response.status);
        const data = await response.json();
        console.log("API Response data:", data);

        if (data.status) {
          setGroups(data.data);
          console.log("Groups fetched:", data.data);
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        console.error('Error fetching groups:', error);
        toast.error('Failed to fetch groups.');
      }
    };

    fetchGroups();
  }, [newEmployee.groupId]);

  const handleAddEmployee = async () => {
    const { name, email, profileImage, notes, groupId } = newEmployee;
    newEmployee.adminId = adminId
    newEmployee.phone = phone
    // Log the new employee details before sending them
    console.log("Adding Employee:", { name, email, phone, profileImage, notes, groupId });

    const formData = new FormData();
    formData.append("adminId", adminId);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("notes", notes);
    formData.append("groupId", groupId);

    if (profileImage) {
      formData.append("profileImage", profileImage);
    }

    try {
      const response = await fetch(`${url}/add-employee`, {
        method: 'POST',
        headers: {

          "Authorization": `bearer ${localStorage.getItem('authToken')}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newEmployee),
      });

      const data = await response.json();
      console.log("API Response after adding employee:", data);

      if (data.status === true) {
        employees.unshift(data.data)
        setEmployees((prev) => [...prev, data.data]);
        toast.success(data.data.message);
        handleCloseAddModal();
        window.location.reload()
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error adding employee:", error);
      toast.error("There was an error adding the employee.");
    }
  };

  const handleCloseAddModal = () => {
    setShowAddModal(false);
    setNewEmployee({
      userName: "",
      email: "",
      phone: "",
      profileImage: null,
      notes: "",
      groupName: "",
    });
    setPhone("")
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewEmployee((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // const handleFileChange = (e) => {
  //   setNewEmployee((prev) => ({
  //     ...prev,
  //     profileImage: e.target.files[0],
  //   }));
  // };

  return (
    <>
      <ToastContainer />
      <div className="flex w-full h-screen">
        <SideMenu />
        <div className="lg:w-[81%] max-sm:w-[100%] md:w-full overflow-scroll scrollbar-hide px-3">
          <Navbar />
          <div className="flex lg:-ml-0 mt-10  sticky top-24  bg-white max-sm:mt-14 space-x-8 sm:ml-5">
            <input
              className="w-[400px] h-12 rounded-xl bg-[#f5f3f3] outline-none pl-4 mb-5"
              style={{ fontFamily: "Montserrat" }}
              placeholder="Search by name or phone..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value.replace(/^\s+/, ""))}
            />

            <button
              className='bg-blue-700 text-white px-5 h-12 rounded-xl '
              style={{ fontFamily: 'Inder' }}
              onClick={() => setShowAddModal(true)}
            >
              + Add Employee
            </button>
          </div>
          <h1 className="text-[22px] font-semibold mt-5 mb- max-sm:mt-5 bg-white md:ml-2">All Employees</h1>
          <div className="p-6 max-sm:p-0 md:ml-2">
            <div className="lg:flex flex-wrap md:flex max-sm:justify-center lg:gap-3 max-sm:space-y-5 sm:-ml-5 sm:gap-4 pt-5 lg:pt-0">

              {load ? (
                <div className="flex justify-center items-center h-full w-full text-center item-center mt-10 font-bold text-lg">
                  <HourglassEmptyIcon className="animate-spin" /> &nbsp; Loading Employees...
                </div>
              ) : filteredEmployees.length > 0 ? (
                filteredEmployees.map((employee, index) => (
                  <EmployeesCard key={index} employee={employee} setEmployees={setEmployees} employees={employees} onEdit={() => {
                    setSelectedEmployee(employee);
                    setShowEditModal(true);
                  }} />
                ))
              ) : (
                <div className="text-semibold italic mt-5">No employees found.</div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Employee Edit Modal */}
      <EmployeeEditModal
        groupId2={selectedEmployee?.groupId}
        open={showEditModal}
        handleClose={() => setShowEditModal(false)}
        employee={selectedEmployee}
        employees={employees}
        setEmployees={setEmployees}
      />

      {/* Add Employee Modal */}
      <Modal open={showAddModal} onClose={handleCloseAddModal}>
        <div className="flex items-center justify-center h-full">
          <div className="bg-white rounded-xl shadow-lg p-6 w-11/12 md:w-1/3 space-y-5">
            <div className="flex justify-end">
              <Button
                onClick={handleCloseAddModal}
                color="secondary"
                className="text-gray-500"
              >
                Cancel
              </Button>
            </div>
            <h2 className="text-lg font-semibold mb-4 text-center">Add New Employee</h2>

            {/* User Name Field */}
            <TextField
              name="name"
              label="User Name"
              value={newEmployee.name}
              onChange={handleChange}
              fullWidth
              className="mb-3"
              InputProps={{
                className: "h-12 bg-gray-50",
              }}
            />

            {/* Email Field */}
            <TextField
              name="email"
              label="Email"
              value={newEmployee.email}
              onChange={handleChange}
              fullWidth
              className="mb-3"
              InputProps={{
                className: "h-12 bg-gray-50",
              }}
            />

            {/* Phone Input */}
            <div className="w-full mb-3">
              <PhoneInput
                country={"in"}
                enableSearch={true}
                value={newEmployee.phone}
                onChange={(phone) => setPhone(phone)}
                inputStyle={{
                  width: "100%",
                  height: "48px",
                  backgroundColor: "#f9fafb",
                }}
              />
            </div>

            {/* File Upload */}
            <div className="mt-4">
              <input
                type="file"
                id="file-upload"
                // onChange={handleFileChange}
                className="hidden"
                accept="image/*"
              />
              <label htmlFor="file-upload" className="block cursor-pointer">
                <TextField
                  name="profileImage"
                  label="Profile Photo"
                  fullWidth
                  className="mb-3"
                  InputProps={{
                    readOnly: true,
                    className: "h-12 bg-gray-50",
                  }}
                  value={newEmployee.profileImage?.name || ''}
                  onClick={(e) => {
                    e.preventDefault();
                    document.getElementById('file-upload').click();
                  }}
                />
              </label>
            </div>

            {/* Group Selection */}
            <TextField
              select
              name="groupId"
              label="Group"
              value={newEmployee.groupId}
              onChange={handleChange}
              fullWidth
              className="mb-3"
              InputProps={{
                className: "h-12 bg-gray-50",
              }}
            >
              {groups.map((group) => (
                <MenuItem key={group._id} value={group._id}>
                  {group.groupName}
                </MenuItem>
              ))}
            </TextField>

            {/* Notes Field */}
            <div >
              <TextField
                name="notes"
                label="Notes"
                value={newEmployee.notes}
                onChange={handleChange}
                fullWidth
                className="mb-3"
              />
            </div>


            <Button
              variant="contained"
              color="primary"
              onClick={handleAddEmployee}
              className="w-full h-12 bg-blue-700 text-white"
            >
              Add Employee
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Employees;
