import React, { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { StaticDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { Box, Typography } from '@mui/material';

const shiftColors = {
  morning: 'shift-morning',
  afternoon: 'shift-afternoon',
  evening: 'shift-evening',
  night: 'shift-night',
};

const initialShifts = {};

function CalendarWithShifts() {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [shifts, setShifts] = useState(initialShifts);

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  const applyShift = (shift) => {
    const formattedDate = selectedDate.format('YYYY-MM-DD');
    setShifts({
      ...shifts,
      [formattedDate]: shift,
    });
  };

  const renderDayContent = (date, _value, DayComponentProps) => {
    const formattedDate = date.format('YYYY-MM-DD');
    const shift = shifts[formattedDate];
    const isSelected = date.isSame(selectedDate, 'day');

    return (
      <Box 
        className={`css-1j3bv1k-MuiButtonBase-root-MuiPickersDay-root ${shift ? shiftColors[shift] : ''}`}
        sx={{
          zIndex:-999,
          height: '48px',
          width: '48px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: `2px solid ${isSelected ? '#4A4A4A' : '#D3D3D3'}`,
          borderTopLeftRadius: '12px',
          borderBottomRightRadius: '12px',
        }}
      >
        <Typography variant="body2">{date.date()}</Typography>
      </Box>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box className="flex items-start flex-col">
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          value={selectedDate}
          onChange={handleDateChange}
          renderDay={renderDayContent}
        />
        <div className="flex text-center  space-x-5 overflow-x-scroll overscroll-x-contain max-sm:w-full scrollbar-hide text-white">
          <div className="bg-[#43C595] border flex-none border-blue-400 px-8 py-2">
            <button onClick={() => applyShift('morning')}>Morning</button>
            <p className="text-[8px]">9am-12pm</p>
          </div>
          <div className="bg-[#8bd248] border flex-none border-blue-400 px-8 py-2">
            <button onClick={() => applyShift('afternoon')}>Afternoon</button>
            <p className="text-[8px]">12pm-3pm</p>
          </div>
          <div className="bg-[#FAC710] border flex-none border-blue-400 px-8 py-2">
            <button onClick={() => applyShift('evening')}>Evening</button>
            <p className="text-[8px]">3pm-6pm</p>
          </div>
         
        </div>
      </Box>
    </LocalizationProvider>
  );
}

export default CalendarWithShifts;
