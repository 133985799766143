import SubscriptionCard from "../../Components/SubscriptionCard";
import { useState } from 'react';

function Subscription() {
  const cards = ["1", "2", "3"]

  const [isMonthly, setIsMonthly] = useState(true);

  return (

    <>
      <div className="p-10 max-sm:p-1">
        <img src="/4Shifter.png" className="w-[200px] max-sm:w-[150px] mt-3 sticky top-2 bg-white" />
        <div className="pt-10 text-center   ">
          <h1 className="text-[40px] max-sm:text-[30px] font-medium max-sm:underline">Select the Perfect Plan for Your Team</h1>
          <h2 className="text-[25px] max-sm:text-[20px] font-medium max-sm:leading-6">Choose Monthly or Discounted Yearly Payments, Free for 21 Days</h2>
          <div className="flex justify-center items-center">
  <div className="border-2 w-[300px] max-sm:w-[300px] text-start border-black max-sm:-mb-5   mt-6 rounded-3xl p-1 flex justify-between">
    <button
      onClick={() => setIsMonthly(true)}
      className={`text-[15px] font-medium rounded-3xl p-1 px-3 ${isMonthly ? 'bg-blue-700 text-white' : 'bg-transparent text-black'}`}
    >
      MONTHLY
    </button>
    <button
      onClick={() => setIsMonthly(false)}
      className={`text-[15px] font-medium rounded-3xl p-1 px-3 ${!isMonthly ? 'bg-blue-700 text-white' : 'bg-transparent text-black'}`}
    >
      ANNUALLY (55% OFF)
    </button>
  </div>
</div>


          <div className="flex lg:justify-center    mt-3 gap-4  w-full overflow-x-auto scrollbar-hide pt-5">
            {cards.map(() => (
              <SubscriptionCard />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Subscription;